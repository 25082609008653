import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <h2>Welcome to profixexpress.com</h2>
      <p>@9trocoder at work</p>
      <h1>Trusted Handyman and Delivery Services at Your Doorstep</h1>
    </div>
  );
}

export default App;
